export const AUTHORIZE = 'AUTHORIZE'
export const LOGOUT = 'LOGOUT'
export const REQUEST_REFRESH_TOKEN = 'REQUEST_REFRESH_TOKEN'

// user
export const REQUEST_USER_INFO = 'REQUEST_USER_INFO'

// time series by sensor type
export const REQUEST_SENSOR_DATA = 'REQUEST_SENSOR_DATA'

// request level
export const REQUEST_LEVEL = 'REQUEST_LEVEL'

// request logger
export const REQUEST_LOGGER_INFO = 'REQUEST_LOGGER_INFO'
export const REQUEST_ASSOCIATED_LOGGER_INFO = 'REQUEST_ASSOCIATED_LOGGER_INFO'

// mashup
export const REQUEST_MASHUP_PERIODS = 'REQUEST_MASHUP_PERIODS'
export const ADD_MASHUP_PERIODS = 'ADD_MASHUP_PERIODS'
export const REMOVE_MASHUP_PERIODS = 'REMOVE_MASHUP_PERIODS'

// report
export const REQUEST_REPORT = 'REQUEST_REPORT'

// export
export const REQUEST_LOGGER_EXPORT = 'REQUEST_LOGGER_EXPORT'
export const REQUEST_LOGGER_FILE_FORMATS = 'REQUEST_LOGGER_FILE_FORMATS'

// overflow year report
export const REQUEST_OVERFLOW_YEAR_REPORT = 'REQUEST_OVERFLOW_YEAR_REPORT'
export const CLEAR_OVERFLOW_YEAR_REPORT = 'CLEAR_OVERFLOW_YEAR_REPORT'

// overflow data for chart
export const REQUEST_OVERFLOW_DATA = 'REQUEST_OVERFLOW_DATA'

// basic weir info without status
export const REQUEST_OVERFLOW = 'REQUEST_OVERFLOW'

// weir overflow status in addition to basic weir info
export const REQUEST_OVERFLOW_STATUS = 'REQUEST_OVERFLOW_STATUS'
export const REQUEST_ALL_OVERFLOW_STATUSES = 'REQUEST_ALL_OVERFLOW_STATUSES'
export const REQUEST_MISSED_OVERFLOW_STATUSES = 'REQUEST_MISSED_OVERFLOW_STATUSES'
export const CLEAR_ALL_OVERFLOW_STATUSES = 'CLEAR_ALL_OVERFLOW_STATUSES'

// year
export const SET_YEAR = 'SET_YEAR'

// checking logger on the list page
export const CHECK_LOGGER = 'CHECK_LOGGER'

// getting phosphor quantity
export const GET_PHOSPHOR_QUANTITY = 'GET_PHOSPHOR_QUANTITY'

// updating phosphor quantity
export const UPDATE_PHOSPHOR_QUANTITY = 'UPDATE_PHOSPHOR_QUANTITY'
export const UPDATE_WEIR_PHOSPHOR_QUANTITY = 'UPDATE_WEIR_PHOSPHOR_QUANTITY'

// update page settings
export const UPDATE_PAGE_SETTINGS = 'UPDATE_PAGE_SETTINGS'
